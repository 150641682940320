import type {TAccount} from "~/utils/ecrf_extracted";

export interface UserAccount extends TAccount {
  name: string;
}

export const useAccountStore = defineStore('account', {
  state: () => {
    return { account: {id: null, first_name: "", last_name: "", is_super_admin: false} as unknown as UserAccount };
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    fetchAccountAsync() {
      useSupaFetch<TAccount[]>("/api/accounts/self").then((accountResponse) => {
        let acc = accountResponse?.data?.value?.data?.find(() => true) ?? {is_super_admin: false, first_name: "", last_name: ""};
        this.account = {
          ...acc,
          name: `${acc.first_name} ${acc.last_name}`
        } as unknown as UserAccount;
      });
    }
  },
})
